// @ts-check

import _, { isNull } from 'lodash'

/**
 * @typedef {import('./search').DocumentId} DocumentId
 */

/**
 * @typedef {Object} StateColumn
 * @prop {string} col
 * @prop {boolean} consolidated
 * @prop {string} currency
 */

/**
 * @class
 */
export default class DocId {
  /** @type {Array.<DocumentId["id"]>} */
  #cats = []
  /** @type {Array.<DocumentId>} */
  #vals = []
  /** @type {StateColumn} */
  #stateColumn = null

  constructor(docPin) {
    if (!_.isEmpty(docPin)) {
      this.#cats = docPin.cats ? _.cloneDeep(docPin.cats) : []
      this.#vals = docPin.vals ? _.cloneDeep(docPin.vals) : []
      this.#stateColumn = docPin.stateColumn
    }
  }

  toJSON () {
    if (!this.#cats.length && !this.#vals.length && isNull(this.#stateColumn)) { return }
    return { cats: this.#cats, vals: this.#vals, stateColumn: this.#stateColumn ? _.defaults(this.#stateColumn, { consolidated: true }) : this.#stateColumn }
  }

  /**
   * @param {DocumentId} item
   * @returns {boolean}
   */
  isPinned (item) {
    if (item.type === 'auto-filters') {
      return this.#cats?.some(f => item.id && f === item.id)
    } else {
      return this.#vals?.some(f => item.id === f.id && item.type === f.type)
    }
  }

  /**
   * @param {DocumentId} item
   */
  setPins (item) {
    if (item.type === 'auto-filters') {
      const index = this.#cats?.findIndex(f => item.id && f === item.id)
      if (index >= 0) {
        this.#cats?.splice(index, 1)
      } else {
        this.#cats?.push(item.id)
      }
    } else {
      const index = this.#vals?.findIndex(f => f.id === item.id && f.type === item.type)
      if (index >= 0) {
        this.#vals?.splice(index, 1)
      } else {
        this.#vals?.push({ id: item.id, type: item.type })
      }
    }
  }

  /**
   * @returns {StateColumn}
   */
  get stateColumn () {
    return this.#stateColumn
  }

  /**
   * @param {StateColumn} args
   * @returns {this}
   */
  setStateColumn (args) {
    this.#stateColumn = args
    return this
  }

  /**
   * @returns {Array.<DocumentId>}
   */
  get vals () {
    return this.#vals
  }

  /**
   * @param {Array<DocumentId>} v
   * @returns {this}
   */
  setVals (v) {
    this.#vals = v
    return this
  }

  /**
   * @returns {Array.<DocumentId["id"]>}
   */
  get cats () {
    return this.#cats
  }

  /**
   * @param {Array.<DocumentId["id"]>} v
   * @returns {this}
   */
  setCats (v) {
    this.#cats = v
    return this
  }
}
